<template>
  <div class="main-wrap">
    <h4 class="title-h4">学习统计</h4>
    <div class="study-info">
      <div class="study-item">
        <img
          class="icon"
          src="@/assets/image/study/20250221-143353.png"
          alt=""
        />
        <div>
          <p class="title">学习天数</p>
          <p class="num">{{ yearData.totalStudyDays }}<i>(天)</i></p>
        </div>
      </div>
      <div class="study-item">
        <img
          class="icon"
          src="@/assets/image/study/20250221-143348.png"
          alt=""
        />
        <div>
          <p class="title">学习时长</p>
          <p class="num">{{ yearData.totalStudyDuration }}<i>(小时)</i></p>
        </div>
      </div>
      <div class="study-item">
        <img
          class="icon"
          src="@/assets/image/study/20250221-143350.png"
          alt=""
        />
        <div>
          <p class="title">学习课程</p>
          <p class="num">{{ yearData.toatalCourseNum }}<i>(个)</i></p>
        </div>
      </div>
      <div class="study-item">
        <img
          class="icon"
          src="@/assets/image/study/20250221-143346.png"
          alt=""
        />
        <div>
          <p class="title">已学课程</p>
          <p class="num">{{ yearData.studyCourseNum }}<i>(个)</i></p>
        </div>
      </div>
    </div>
    <!-- tab切换 -->
    <div class="tabs">
      <p
        v-show="tabMenu.length > 1"
        class="tabItem"
        @click="tabChange(item.type)"
        :class="{ active: item.type == tabIndex }"
        v-for="(item, index) in tabMenu"
        :key="index"
      >
        {{ item.name }}
      </p>
    </div>

    <!-- 数据统计信息 -->
    <div class="statistics-box">
      <!-- 昨日数据统计 -->
      <div class="statistics-day" v-if="tabIndex == 1">
        <div class="public-statistics statistics-item bgColor1">
          <p class="title">学习时长</p>
          <p class="num">{{ statisticsDay.studyDuration || 0 }}<i>分钟</i></p>
          <p
            class="contrast"
            v-html="evaluatePerformance(statisticsDay.studyDurationRank)"
          ></p>
        </div>
        <div class="public-statistics statistics-item bgColor2">
          <p class="title">学完章节数</p>
          <p class="num">{{ statisticsDay.sectionsStudied || 0 }}<i>个</i></p>
          <p
            class="contrast"
            v-html="evaluatePerformance(statisticsDay.sectionsStudiedRank)"
          ></p>
        </div>
        <div class="public-statistics statistics-item bgColor3">
          <p class="title">持续登录天数</p>
          <p class="num">
            {{ statisticsDay.consecutiveLoginDays || 0 }}<i>天 </i>
          </p>
          <p
            class="contrast"
            v-html="evaluatePerformance(statisticsDay.loginRank)"
          ></p>
        </div>
        <div class="public-statistics statistics-item bgColor4">
          <p class="title">持续打卡天数</p>
          <p class="num">
            {{ statisticsDay.consecutiveCheckinDays || 0 }}<i>天</i>
          </p>
          <p
            class="contrast"
            v-html="evaluatePerformance(statisticsDay.checkinRank)"
          ></p>
        </div>
        <div class="public-statistics statistics-item bgColor5">
          <p class="title">持续学习天数</p>
          <p class="num">
            {{ statisticsDay.consecutiveLearningDays || 0 }}<i>天</i>
          </p>
          <p
            class="contrast"
            v-html="evaluatePerformance(statisticsDay.consecutiveLearningRank)"
          ></p>
        </div>
      </div>
      <!-- 周数据统计 -->
      <div class="statistics-week" v-if="tabIndex == 2">
        <div class="lineCharts-inner">
          <div class="head-data">
            <!-- <div>
              <p class="name">日期：{{ hoverDate }}</p>
              <p class="name">
                <i>{{ hoverStudy }}</i
                >分钟
              </p>
            </div>
            <div>
              <p class="name">近七天平均时间：</p>
              <p class="name">
                <i>{{ hoverStudyAvg }}</i
                >分钟
              </p>
            </div> -->
            <div class="time">{{ dateRamge }}</div>
          </div>
          <div ref="lineChartsRef" class="lineCharts"></div>
        </div>
        <div class="side-right">
          <div class="public-statistics statistics-item bgColor1">
            <p class="title">学习总时长</p>
            <p class="num">
              {{ statisticsWeek.totalStudyDuration || 0 }}<i>分钟</i>
            </p>
          </div>
          <div class="public-statistics statistics-item bgColor5">
            <p class="title">学完章节数</p>
            <p class="num">
              {{ statisticsWeek.totalSectionsStudied || 0 }}<i>个</i>
            </p>
          </div>
          <div
            style="margin-bottom: 0"
            class="public-statistics statistics-item bgColor2"
          >
            <p class="title">总打卡天数</p>
            <p class="num">
              {{ statisticsWeek.totalCheckinDays || 0 }}<i>天</i>
            </p>
          </div>
          <div
            style="margin-bottom: 0"
            class="public-statistics statistics-item bgColor3"
          >
            <p class="title">总学习天数</p>
            <p class="num">{{ statisticsWeek.totalStudyDays || 0 }}<i>天</i></p>
          </div>
        </div>
      </div>
      <!-- 月数据统计 -->
      <div
        class="statistics-week"
        v-if="tabIndex == 3 && statisticsMounth.monthStudy"
      >
        <div class="lineCharts-inner">
          <Calendar :monthCheckinList="statisticsMounth.monthCheckinList" />
        </div>
        <div class="side-right">
          <div class="public-statistics statistics-item bgColor1">
            <p class="title">打卡天数</p>
            <p class="num">
              {{ statisticsMounth.monthStudy.checkedInUsers }}<i>分钟</i>
            </p>
            <p
              class="contrast"
              v-html="
                evaluatePerformance(statisticsMounth.monthStudy.checkedRank)
              "
            ></p>
          </div>
          <div class="public-statistics statistics-item bgColor5">
            <p class="title">学习天数</p>
            <p class="num">
              {{ statisticsMounth.monthStudy.studiedUsers }}<i>天</i>
            </p>
            <p
              class="contrast"
              v-html="
                evaluatePerformance(statisticsMounth.monthStudy.studiedRank)
              "
            ></p>
          </div>
          <div class="public-statistics statistics-item bgColor2">
            <p class="title">累计学习时长</p>
            <p class="num">
              {{ statisticsMounth.monthStudy.totalStudyDuration }}<i>分钟</i>
            </p>
            <p
              class="contrast"
              v-html="
                evaluatePerformance(
                  statisticsMounth.monthStudy.totalStudyDurationRank
                )
              "
            ></p>
          </div>
          <div class="public-statistics statistics-item bgColor3">
            <p class="title">单日最长学习</p>
            <p class="num">
              {{ statisticsMounth.monthStudy.maxDailyDuration }}<i>分钟</i>
            </p>
            <p
              class="contrast"
              v-html="
                evaluatePerformance(
                  statisticsMounth.monthStudy.maxDailyDurationRank
                )
              "
            ></p>
          </div>
          <div class="situation">
            <p class="title">您在本月的学习表现：</p>
            <p class="p">
              <img
                src="@/assets/image/study/20250221-165357.png"
                alt=""
              />本月学习天数
              <i>{{ statisticsMounth.monthStudy.studiedUsers }}</i> 天，<span
                v-html="funAbs('studiedUsers', 'lastStudiedDays', 1)"
              ></span>
            </p>
            <p class="p">
              <img
                src="@/assets/image/study/20250221-165354.png"
                alt=""
              />本月平均学习时间
              <i>{{ statisticsMounth.monthStudy.averageStudyDuration }}</i>
              分钟，<span
                v-html="
                  funAbs('averageStudyDuration', 'lastAverageDuration', 2)
                "
              ></span>
            </p>
            <p class="p">
              <img
                src="@/assets/image/study/20250221-165351.png"
                alt=""
              />本月最早学习时间为
              <i>{{ statisticsMounth.monthStudy.earliestStudyTime }}</i>
              ，最晚学习时间为
              <i>{{ statisticsMounth.monthStudy.latestStudyTime }}</i> ，
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import Calendar from './Calendar.vue'
export default {
  // 可用组件的哈希表
  components: {Calendar},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      tabIndex: 1,
      tabMenu: [
        {
          type: 1,
          name: "昨日",
        },
        {
          type: 2,
          name: "近七天",
        },
        {
          type: 3,
          name: "本月",
        },
      ],
      yearData: {}, //年度总数据
      statisticsDay: {}, //昨日数据
      statisticsWeek: {}, //近七天总数据
      studyDates: [], // 近七天日期
      avgDurations: [], // 近七天学习平均值
      studyDurations: [], // 近七天学习时长
      hoverDate: "", // 所选日期
      hoverStudy: "", // 所选日期学习时长
      hoverStudyAvg: "", // 所选日期学习时长平均数
      dateRamge: "", // 统计区间
      statisticsMounth: {}, //月数据
    };
  },
  // 事件处理器
  methods: {
    // tab切换
    tabChange(type) {
      if (type != this.tabIndex) {
        this.tabIndex = type;
        this.getStudyList(this.tabIndex);
      }
    },
    // 查询用户年度学习数据
    getYearAnnualData() {
      this.$ajax({
        url: "/hxclass-pc/user/study/statistics/annual/data",
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.yearData = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 查询用户学习统计数据  type=1：昨日  2：近七天  3：本月
    getStudyList(type) {
      this.$ajax({
        url: "/hxclass-pc/user/study/statistics/list",
        method: "get",
        params: {
          type: type,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          if (type == 1) {
            // 昨日
            this.statisticsDay = res.data;
          } else if (type == 2) {
            // 近七天
            this.statisticsWeek = res.data;
            this.studyDates = this.statisticsWeek.weekGraphicLis.map(
              (item) => item.studyDate
            );
            this.avgDurations = this.statisticsWeek.weekGraphicLis.map(
              (item) => item.avgDuration
            );
            this.studyDurations = this.statisticsWeek.weekGraphicLis.map(
              (item) => item.studyDuration
            );
            this.dateRamge = `${this.studyDates[0]} ~ ${
              this.studyDates[this.studyDates.length - 1]
            }`;
            this.hoverDate = this.studyDates[this.studyDates.length - 1];
            this.hoverStudyAvg =
              this.avgDurations[this.avgDurations.length - 1];
            this.hoverStudy =
              this.studyDurations[this.studyDurations.length - 1];
            this.$nextTick(() => {
              this.myChartInit();
            });
          } else if (type == 3) {
            // 本月
            this.statisticsMounth = res.data;
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 处理绝对值
    funAbs(name1, name2, type) {
      // e.数据  name.业务  type.类型
      let res = "";
      let absNum = Math.abs(
        this.statisticsMounth.monthStudy[name1] -
          this.statisticsMounth.monthStudy[name2]
      );
      if (
        this.statisticsMounth.monthStudy[name1] -
          this.statisticsMounth.monthStudy[name2] >
        0
      ) {
        res = `比上个月多 <i>${absNum}</i> ${type == 1 ? "天" : "分钟"}`;
      } else if (
        this.statisticsMounth.monthStudy[name1] -
          this.statisticsMounth.monthStudy[name2] ==
        0
      ) {
        res = `和上月持平`;
      }
      {
        res = `比上个月少 <i>${absNum}</i> ${type == 1 ? "天" : "分钟"}`;
      }
      return res;
    },
    // 处理排名
    evaluatePerformance(rank) {
      let x = rank || 0;
      // 检查x是否为0或者是一个假值（如：undefined, null, NaN, 空字符串）
      if (x === 0 || !x) {
        return "(已落后<i>100%</i>同学)";
      }

      // 检查x是否大于50
      if (x > 50) {
        return `(已超过<i>${x}%</i>同学)`;
      }

      // 如果x小于等于50但不为0或其他假值
      if (x <= 50 && x > 0) {
        let behindPercentage = 100 - x;
        return `(已落后<i>${behindPercentage}%</i>同学)`;
      }

      // 默认情况下，如果输入不符合上述条件，可以抛出错误或返回特定消息
      throw new Error("输入的X值不在预期范围内。");
    },
    // 创建折线图
    myChartInit() {
      let _that = this;
      var myChart = echarts.getInstanceByDom(this.$refs.lineChartsRef);
      if (!myChart) {
        myChart = echarts.init(this.$refs.lineChartsRef);
      }
      var option = {
        grid: {
          left: "0%", // 可以根据需要调整
          right: "3%",
          top: "6%", // 根据实际情况调整顶部空间，为标题或其它元素留出空间
          bottom: "0%", // 根据实际情况调整底部空间
          containLabel: true, // 确保标签（如数据标签）完全显示在grid区域内
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
          formatter: function (params) {
            // 假设我们只关注第一个系列的数据，即柱状图的数据
            let param = params[0];
            let avg = params[1];
            return `
              <div>
                <div>日期：${param.name}</div>
                <div>学习时长：${param.value} 分钟</div>
                <div>平均学习时长：${avg.value} 分钟</div>
              </div>
            `;
          },
        },
        xAxis: [
          {
            type: "category",
            data: this.studyDates,
            axisTick: {
              show: false, // 隐藏X轴刻度
            },
            axisLine: {
              lineStyle: {
                type: "dashed", // 设置X轴线为虚线
                color: "rgba(0,0,0,0.2)", // 可选：设置虚线的颜色
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
            axisLabel: {
              formatter: "{value}",
            },
          },
          {
            type: "value",
            name: "",
          },
        ],
        series: [
          {
            name: "平均学习时长",
            type: "bar",
            data: this.studyDurations,
            label: {
              show: true, // 显示标签
              position: "top", // 标签位置，在柱子顶部
              color: "#666666", // 标签颜色
              fontSize: 10, // 字体大小
              formatter: "{c}", // 自定义显示格式，这里加上了单位“分钟”
            },
            barWidth: "10px",
            itemStyle: {
              color: "#15B7DD", // 设置条颜色
              borderRadius: [5, 5, 5, 5],
            },
          },
          {
            name: "学习时长",
            type: "line",
            smooth: true, // 可选：是否平滑曲线
            itemStyle: {
              color: "#15B7DD", // 设置数据点的颜色
              borderColor: "#ffffff", // 数据点边框颜色设为白色
              borderWidth: 2, // 数据点边框宽度，可以根据需要调整
            },
            emphasis: {
              //鼠标悬浮效果
              itemStyle: {
                color: "#ffffff", // 设置数据点的颜色
                borderColor: "#15B7DD", // 数据点边框颜色设为白色
                borderWidth: 2, // 数据点边框宽度，可以根据需要调整
              },
            },
            symbolSize: 11, // 可选：设置数据点大小
            symbol: "circle", // 不显示数据点符号
            data: this.avgDurations,
            lineStyle: {
              color: "#FFA635", // 设置线条颜色
            },
          },
        ],
      };
      myChart.setOption(option);
      // 添加点击事件监听器
      myChart.on("click", function (params) {
        _that.hoverDate = _that.studyDates[params.dataIndex];
        _that.hoverStudyAvg = _that.avgDurations[params.dataIndex];
        _that.hoverStudy = _that.studyDurations[params.dataIndex];
      });
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getYearAnnualData();
    this.getStudyList(this.tabIndex);
  },
  // 生命周期-实例挂载后调用
  mounted() {
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {
  },
  // 自定义的侦听器
  watch: {
    tabIndex() {
      if (this.tabIndex == 2) {
        this.$nextTick(() => {
          this.myChartInit();
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.study-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  .study-item {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f6f6fc;
    width: calc((100% - 60px) / 4);
    padding: 14px 0;
    border-radius: 5px;
    position: relative;
    &::after {
      content: "";
      width: 55px;
      height: 48px;
      position: absolute;
      right: 0;
      bottom: 0;
      background-image: url(../../assets/image/study/20250221-143342.png);
      background-size: 100% 100%;
    }
    .icon {
      margin-right: 15px;
      width: 32px;
      height: 32px;
    }
    .title {
      font-weight: 500;
      font-size: 16px;
      color: #333333;
      line-height: 24px;
    }
    .num {
      font-weight: 500;
      font-size: 32px;
      color: #333333;
      line-height: 48px;
      i {
        margin-left: 5px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #333333;
        line-height: 18px;
      }
    }
  }
}
.tabs {
  display: inline-block;
  position: relative;
  left: -40px;
  padding: 0 40px;
  margin-bottom: 20px;
  background-color: rgba(21, 183, 221, 0.1);
  .tabItem {
    display: inline-block;
    padding: 0 38px;
    line-height: 38px;
    font-size: 18px;
    color: #666666;
    margin-right: 20px;
    cursor: pointer;
  }
  .tabItem:last-child {
    margin: 0;
  }
  .active {
    color: #ffffff;
    background-color: #15b7dd;
  }
}
.statistics-box {
  .public-statistics {
    padding: 20px;
    border-radius: 12px;
    position: relative;
    &::after {
      content: "";
      width: 55px;
      height: 48px;
      position: absolute;
      right: 0;
      bottom: 0;
      background-image: url(../../assets/image/study/20250221-143342.png);
      background-size: 100% 100%;
    }
    .title {
      font-weight: 500;
      font-size: 16px;
      color: #333333;
      line-height: 24px;
    }
    .num {
      font-weight: 500;
      font-size: 32px;
      color: #333333;
      line-height: 48px;
      i {
        margin-left: 5px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #333333;
        line-height: 24px;
      }
    }
    .contrast {
      margin-top: 20px;
      margin-bottom: 8px;
      font-weight: 400;
      font-size: 12px;
      color: #666666;
      line-height: 18px;
      /deep/ i {
        color: #ff452d;
        font-style: normal;
      }
    }
  }
  .bgColor1 {
    background: #eff6ff;
    border: 1px solid #a3c7e1;
    border-bottom: 6px solid #a3c7e1;
  }
  .bgColor2 {
    background: #f2fff7;
    border: 1px solid #ace7c3;
    border-bottom: 6px solid #ace7c3;
  }
  .bgColor3 {
    background: #fff7f6;
    border: 1px solid #e7bcb6;
    border-bottom: 6px solid #e7bcb6;
  }
  .bgColor4 {
    background: #fffdf6;
    border: 1px solid #e78cea;
    border-bottom: 6px solid #e78cea;
  }
  .bgColor5 {
    background: #f2feff;
    border: 1px solid #9cdbe0;
    border-bottom: 6px solid #9cdbe0;
  }
  .statistics-day {
    display: flex;
    justify-content: space-between;
    .statistics-item {
      width: calc((100% - 80px) / 5);
    }
  }
  .statistics-week {
    display: flex;
    .lineCharts-inner {
      width: 430px;
      min-width: 430px;
      .head-data {
        text-align: right;
        margin-bottom: 12px;
        margin-top: 8px;
        .name {
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          line-height: 21px;
          i {
            color: #ec6c01;
            font-style: normal;
          }
        }
        .time {
          font-weight: 400;
          font-size: 12px;
          color: #999999;
          line-height: 18px;
        }
      }
      .lineCharts {
        min-width: 430px;
        height: 200px;
      }
    }
    .side-right {
      flex: 1;
      margin-left: 30px;
      display: flex;
      flex-wrap: wrap;
      .statistics-item {
        margin-right: 16px;
        margin-bottom: 17px;
        width: calc((100% - 18px) / 2);
        .contrast {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
      .statistics-item:nth-child(2n) {
        margin-right: 0;
      }
      .situation {
        padding: 12px 20px;
        border-radius: 12px;
        width: 100%;
        background: #f2feff;
        .title {
          font-weight: 500;
          font-size: 14px;
          color: #333333;
          line-height: 21px;
        }
        .p {
          margin-top: 10px;
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          line-height: 21px;
          img {
            width: 16px;
            margin-right: 2px;
          }
          /deep/ i {
            font-weight: 500;
            color: #111111;
            font-style: normal;
          }
        }
      }
    }
  }
}
</style>
