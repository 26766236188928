<template>
  <div class="calendar-control">
    <div class="head-title">
      <img src="@/assets/image/study/20250225-093305.png" alt="">
      <span>{{currentYear}}年{{currentMonth}}月</span>
    </div>
    <div class="date-table">
      <div class="thead">
        <span class="th">日</span>
        <span class="th">一</span>
        <span class="th">二</span>
        <span class="th">三</span>
        <span class="th">四</span>
        <span class="th">五</span>
        <span class="th">六</span>
      </div>
      <div class="tbody">
        <div class="td" v-for="(item,index) in chunkedCalendarData" :key="index">
          <div class="t-inner" :class="{'t-study' : item && item.studyFlag, 't-clock' : item && item.checkinFlag }">
            <p class="day">{{item && item.day || ''}}</p>
            <p class="lunar">{{item && funFormatDate(item.lunar) || ''}}</p>
          </div>
        </div>
      </div>
      <div class="state">
        <span class="icon1">已学习</span>
        <span class="icon2">已打卡</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {
    monthCheckinList: {
      type: Array,
      default: ()=>[]
    }
  },
  // 数据对象
  data () {
    return {
      currentYear: new Date().getFullYear(),
      currentMonth: new Date().getMonth() + 1, // 月份从0开始，+1调整为正常月份
      calendarData: [],
    }
  },
  // 事件处理器
  methods: {
    funFormatDate(str){
      if (str.endsWith('初一')) {
        // 截取“初一”之前的部分
        return str.substring(0, str.length - 2).trim();
      } else {
        // 否则，直接截取最后两个字符
        return str.slice(-2);
      }
    },
    generateCalendar() {
      const year = this.currentYear;
      const month = this.currentMonth;
      const daysInMonth = new Date(year, month, 0).getDate(); // 获取当月天数
      const firstDayOfWeek = new Date(year, month - 1, 1).getDay(); // 获取当月第一天是星期几（0=周日，6=周六）

      // 计算需要填充的空数据条数
      const emptyCount = (firstDayOfWeek + 7) % 7; // 如果第一天是周六（6），则需要填充6个空数据

      // 生成日期数组
      const dateArray = Array(emptyCount).fill(null); // 填充空数据
      for (let day = 1; day <= daysInMonth; day++) {
        const lunarDate = this.getLunarDate(year, month, day); // 获取农历日期
        dateArray.push({
          date: `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`,
          day: day,
          lunar: lunarDate
        });
      }

      this.calendarData = dateArray;
    },

    getLunarDate(year, month, day) {
      // 农历信息表
      var lunarInfo = [
        0x04bd8,
        0x04ae0,
        0x0a570,
        0x054d5,
        0x0d260,
        0x0d950,
        0x16554,
        0x056a0,
        0x09ad0,
        0x055d2,
        0x04ae0,
        0x0a5b6,
        0x0a4d0,
        0x0d250,
        0x1d255,
        0x0b540,
        0x0d6a0,
        0x0ada2,
        0x095b0,
        0x14977,
        0x04970,
        0x0a4b0,
        0x0b4b5,
        0x06a50,
        0x06d40,
        0x1ab54,
        0x02b60,
        0x09570,
        0x052f2,
        0x04970,
        0x06566,
        0x0d4a0,
        0x0ea50,
        0x06e95,
        0x05ad0,
        0x02b60,
        0x186e3,
        0x092e0,
        0x1c8d7,
        0x0c950,
        0x0d4a0,
        0x1d8a6,
        0x0b550,
        0x056a0,
        0x1a5b4,
        0x025d0,
        0x092d0,
        0x0d2b2,
        0x0a950,
        0x0b557,
        0x06ca0,
        0x0b550,
        0x15355,
        0x04da0,
        0x0a5b0,
        0x14573,
        0x052b0,
        0x0a9a8,
        0x0e950,
        0x06aa0,
        0x0aea6,
        0x0ab50,
        0x04b60,
        0x0aae4,
        0x0a570,
        0x05260,
        0x0f263,
        0x0d950,
        0x05b57,
        0x056a0,
        0x096d0,
        0x04dd5,
        0x04ad0,
        0x0a4d0,
        0x0d4d4,
        0x0d250,
        0x0d558,
        0x0b540,
        0x0b6a0,
        0x195a6,
        0x095b0,
        0x049b0,
        0x0a974,
        0x0a4b0,
        0x0b27a,
        0x06a50,
        0x06d40,
        0x0af46,
        0x0ab60,
        0x09570,
        0x04af5,
        0x04970,
        0x064b0,
        0x074a3,
        0x0ea50,
        0x06b58,
        0x05ac0,
        0x0ab60,
        0x096d5,
        0x092e0, //1990
        0x0c960,
        0x0d954,
        0x0d4a0,
        0x0da50,
        0x07552,
        0x056a0,
        0x0abb7,
        0x025d0,
        0x092d0,
        0x0cab5,
        0x0a950,
        0x0b4a0,
        0x0baa4,
        0x0ad50,
        0x055d9,
        0x04ba0,
        0x0a5b0,
        0x15176,
        0x052b0,
        0x0a930,
        0x07954,
        0x06aa0,
        0x0ad50,
        0x05b52,
        0x04b60,
        0x0a6e6,
        0x0a4e0,
        0x0d260,
        0x0ea65,
        0x0d530,
        0x05aa0,
        0x076a3,
        0x096d0,
        0x04bd7,
        0x04ad0,
        0x0a4d0,
        0x1d0b6,
        0x0d250,
        0x0d520,
        0x0dd45,
        0x0b5a0,
        0x056d0,
        0x055b2,
        0x049b0,
        0x0a577,
        0x0a4b0,
        0x0aa50,
        0x1b255,
        0x06d20,
        0x0ada0,
        0x14b63,
      ];

      function lYearDays(y) {
        var i,
          sum = 348;
        for (i = 0x8000; i > 0x8; i >>= 1)
          sum += lunarInfo[y - 1900] & i ? 1 : 0;
        return sum + leapDays(y);
      }

      function leapDays(y) {
        if (leapMonth(y)) return lunarInfo[y - 1900] & 0x10000 ? 30 : 29;
        else return 0;
      }

      function leapMonth(y) {
        return lunarInfo[y - 1900] & 0xf;
      }

      function monthDays(y, m) {
        return lunarInfo[y - 1900] & (0x10000 >> m) ? 30 : 29;
      }

      function Lunar(y, m, d) {
        var i,
          leap = 0,
          temp = 0;
        var offset = (Date.UTC(y, m, d) - Date.UTC(1900, 0, 31)) / 86400000;
        for (i = 1900; i < 2050 && offset > 0; i++) {
          temp = lYearDays(i);
          offset -= temp;
        }
        if (offset < 0) {
          offset += temp;
          i--;
        }
        this.year = i;
        leap = leapMonth(i);
        this.isLeap = false;
        for (i = 1; i < 13 && offset > 0; i++) {
          if (leap > 0 && i == leap + 1 && this.isLeap == false) {
            --i;
            this.isLeap = true;
            temp = leapDays(this.year);
          } else {
            temp = monthDays(this.year, i);
          }
          if (this.isLeap == true && i == leap + 1) this.isLeap = false;
          offset -= temp;
        }
        if (offset == 0 && leap > 0 && i == leap + 1)
          if (this.isLeap) {
            this.isLeap = false;
          } else {
            this.isLeap = true;
            --i;
          }
        if (offset < 0) {
          offset += temp;
          --i;
        }
        this.month = i;
        this.day = offset + 1;
      }

      var nStr1 = [
        "",
        "一",
        "二",
        "三",
        "四",
        "五",
        "六",
        "七",
        "八",
        "九",
        "十",
        "十一",
        "十二",
      ];
      var nStr2 = ["初", "十", "廿", "卅", ""];

      function GetcDay(d) {
        var s;
        switch (d) {
          case 10:
            return "初十";
          case 20:
            return "二十";
          case 30:
            return "三十";
          default:
            s = nStr2[Math.floor(d / 10)];
            s += nStr1[d % 10];
            break;
        }
        return s;
      }

      function GetcMon(m) {
        if (m === 1) return "正";
        else return nStr1[m];
      }

      function getLunar() {
        var lObj = new Lunar(year, month - 1, day);
        let lmonth = GetcMon(lObj.month);
        let lday = GetcDay(lObj.day);
        let lleap = lObj.isLeap;

        if (lleap) {
          lmonth = "闰" + lmonth;
        }
        return `${lmonth}月${lday}`;
      }

      return getLunar();
    },
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.generateCalendar();
  },
  // 生命周期-实例挂载后调用
  mounted () {
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {
    chunkedCalendarData() {
      return this.calendarData.map(item1 => {
        if (item1 && item1.date) {
          const match = this.monthCheckinList.find(item2 => item2.studyDate === item1.date);
          if (match) {
            // 如果找到匹配项，则合并对象
            return {
              date: item1.date,
              day: item1.day,
              lunar: item1.lunar,
              checkinFlag: match.checkinFlag,
              studyFlag: match.studyFlag
            };
          }
        }
        // 如果没有匹配项或arry1中的元素是null，则返回原对象
        return item1;
      });
    }
  },
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.calendar-control{
  background: #F1F5FF;
  padding: 6px;
  border-radius: 10px;
  .head-title{
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    color: #5C6F9E;
    margin: 2px 0 7px;
    line-height: 21px;
    img{
      width: 15px;
      margin-right: 2px;
      margin-bottom: 4px;
    }
  }
  .date-table{
    background-color: #ffffff;
    border-radius: 5px;
    padding: 32px 10px 24px;
    .thead, .tbody{
      display: flex;
      flex-wrap: wrap;
    }
    .thead{
      margin-bottom: 12px;
    }
    .tbody{
      position: relative;
      padding-top: 6px;
      padding-bottom: 24px;
      &::after{
        content: '';
        position: absolute;
        top: 0;
        left: 14px;
        display: inline-block;
        width: 80%;
        height: .1px;
        background:  rgba(0,0,0,0.1);
      }
      &::before{
        content: '';
        position: absolute;
        bottom: 0;
        left: 14px;
        display: inline-block;
        width: calc(100% - 28px);
        height: .1px;
        background:  rgba(0,0,0,0.1);
      }
    }
    .th, .td{
      display: inline-block;
      text-align: center;
      width: calc(100% / 7);
      color: #C4C4C4;
      margin: 8px 0;
    }
    .td{
      .day{
        font-weight: 500;
        font-size: 14px;
        color: #333333;
        line-height: 21px;
      }
      .lunar{
        font-weight: 400;
        font-size: 10px;
        color: #C4C4C4;
        line-height: 15px;
      }
      .t-inner{
        display: inline-block;
        position: relative;
        padding: 4px 7px;
        border-radius: 16px 16px 16px 16px;
      }
      .t-study{
        background: rgba(76,191,0,0.1);
        .day{
          color: #4CBF00;
        }
        .lunar{
          color: rgba(76,191,0,0.4);
        }
      }
      .t-clock{
        &::after{
          content: '';
          position: absolute;
          right: -8px;
          top: 0px;
          width: 15px;
          height: 15px;
          background-image: url(~@/assets/image/study/20250225-01.png);
          background-size: 100% 100%;
        }
      }
    }
    .state{
      padding-right: 20px;
      margin-top: 14px;
      text-align: right;
      font-weight: 400;
      font-size: 12px;
      color: #666666;
      line-height: 18px;
      span{
        margin-left: 50px;
        position: relative;
      }
      .icon1{
        &::after{
          content: '';
          position: absolute;
          border-radius: 16px;
          left: -18px;
          top: 0px;
          width: 13px;
          height: 17px;
          background: #EDF9E6;
        }
      }
      .icon2{
        &::after{
          content: '';
          position: absolute;
          left: -18px;
          top: 1px;
          width: 15px;
          height: 15px;
          background-image: url(~@/assets/image/study/20250225-01.png);
          background-size: 100% 100%;
        }
      }
    }
  }
}
</style>
